import { css } from "@emotion/css";
import { ClickAwayListener, Fade, Popper } from "@material-ui/core";
import { borderRadius, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { PropsWithChildren } from "react";
export interface PopoverProps {
    placement: PopoverPlacement;
    onClose?: () => void;
    anchorEl: null | HTMLElement;
    open: boolean;
    elementId?: string;
    accessibleName?: string;
    accessibleRole?: PopoverAriaRole;
    boundaryPadding?: number;
}
export const popoverPlacementOptions = ["bottom-end", "bottom-start", "top-start", "top-end", "left-start", "left-end", "right-start", "right-end"] as const;
export type PopoverPlacement = (typeof popoverPlacementOptions)[number];
export type PopoverAriaRole = "menu" | "dialog";
export function Popover({ children, open, anchorEl, placement = "left-start", onClose, elementId, accessibleName, accessibleRole = "dialog", boundaryPadding }: PropsWithChildren<PopoverProps>) {
    const onCloseClicked = () => {
        onClose?.();
    };
    if (!anchorEl) {
        return null;
    }
    return (<Popper id={elementId} role={accessibleRole} aria-label={accessibleName} className={popoverContainerStyles} open={open} anchorEl={anchorEl} placement={placement} modifiers={{
            flip: {
                enabled: false,
            },
            offset: { enabled: true, offset: "0,8" },
            preventOverflow: {
                enabled: true,
                padding: boundaryPadding,
                boundariesElement: "window",
            },
        }} transition>
            {({ TransitionProps }) => (<ClickAwayListener onClickAway={onCloseClicked}>
                    <Fade {...TransitionProps} timeout={{ enter: 350, exit: 100 }}>
                        <div>{children}</div>
                    </Fade>
                </ClickAwayListener>)}
        </Popper>);
}
const popoverContainerStyles = css({
    zIndex: "1300", // mui components visible in popover
    backgroundColor: themeTokens.color.popover.background.primary,
    boxShadow: themeTokens.shadow.medium,
    borderRadius: borderRadius.large,
    overflow: "clip",
    minHeight: "77px",
    font: text.regular.default.medium,
    color: themeTokens.color.text.primary,
});
