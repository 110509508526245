import { css } from "@emotion/css";
import { colorScales, space } from "@octopusdeploy/design-system-tokens";
import React, { useState, createRef } from "react";
import { InfoPopover } from "../Icon";
import { PopoverBasic, type PopoverBasicProps } from "./PopoverBasic";
export interface PopoverBasicHelpProps extends Omit<PopoverBasicProps, "open" | "anchorEl"> {
    onOpen?: () => void;
}
export function PopoverBasicHelp({ onOpen, ...props }: PopoverBasicHelpProps): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = createRef<HTMLDivElement>();
    const preventPrematureClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
    };
    const handleIconClick = () => {
        anchorEl ? handleClose() : handleOpen();
    };
    const handleOpen = () => {
        setAnchorEl(ref.current);
        onOpen?.();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<div ref={ref} tabIndex={0} onClick={preventPrematureClose} className={styles.popoverBasicHelpContainer}>
            <div onClick={handleIconClick} className={styles.infoIconContainer}>
                <InfoPopover />
            </div>
            <PopoverBasic onClose={handleClose} anchorEl={anchorEl} open={!!anchorEl} {...props}/>
        </div>);
}
const styles = {
    popoverBasicHelpContainer: css({
        display: "inline-block",
        position: "relative",
        bottom: "-7px",
        "&:focus div": {
            boxShadow: `inset 0 0 0 2px ${colorScales.blue[300]}`,
            borderRadius: "50%",
        },
    }),
    infoIconContainer: css({
        width: space[24],
        height: space[24],
    }),
};
