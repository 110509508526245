/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import debounce from "lodash.debounce";
import { useEffect, useState, type ReactElement, type ReactNode } from "react";
import * as React from "react";
import { useIsLargerThanIpadResolution } from "../../hooks";
import type { DesignSystemLinkHref } from "../../routing";
import type { BreadcrumbItem } from "../Breadcrumbs";
import type { SimpleMenuItem } from "../MenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions";
import { PageHeaderPrimary, type PageHeaderPrimaryLogo } from "../PageHeaderPrimary";
import { useSetInPageNavVisible } from "./InPageNavVisibilityContext";
import type { MenuNodeItem } from "./MenuNode";
import type { PageSideNavItem, PageSideNavLink } from "./PageSideNav";
import { isPageSideNavLink, PageSideNav } from "./PageSideNav";
import PriorityNavigation from "./PriorityNavigation";
interface Level2PageLayoutProps {
    navItems?: PageSideNavItem[];
    content: ReactNode;
    header?: {
        breadcrumbs?: BreadcrumbItem[];
        logo?: PageHeaderPrimaryLogo;
        title: string;
        logoLinkURL?: DesignSystemLinkHref;
        titleIcon?: ReactElement;
        titleChips?: ReactElement[];
        primaryAction?: PrimaryPageAction;
        actions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
        statusSection?: React.ReactNode;
    };
    callout?: ReactNode;
    tourProgress?: ReactNode;
    description?: ReactNode;
}
export function Level2PageLayout({ navItems = [], content, description, header, callout, tourProgress }: Level2PageLayoutProps) {
    const setIsInPageNavVisible = useSetInPageNavVisible();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [hasEnoughSpaceForSideNav, setHasEnoughSpaceForSideNav] = useState(false);
    useEffect(() => {
        if (!containerRef.current)
            return;
        function onContainerResize(entries: ResizeObserverEntry[]) {
            // This value is chosen as the minimum width where the Process Editor doesn't feel cramped with the sidenav
            const minimumWidthToFitSideNav = 1050;
            const containerWidth = entries[0].contentRect.width;
            setHasEnoughSpaceForSideNav(containerWidth >= minimumWidthToFitSideNav);
        }
        const containerResizeObserver = new ResizeObserver(debounce(onContainerResize, 100, { leading: true }));
        containerResizeObserver.observe(containerRef.current);
        return () => containerResizeObserver.disconnect();
    }, [containerRef]);
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const hasItems = navItems.length > 0;
    React.useEffect(() => {
        setIsInPageNavVisible?.(hasItems);
        return () => setIsInPageNavVisible?.(false);
    }, [hasItems, setIsInPageNavVisible]);
    const sideNavView = (<div className={sideBarLayoutStyles}>
            {hasItems && <PageSideNav items={navItems} tourProgress={tourProgress} description={description}/>}
            <div className={cx(sideBarLayoutContentStyles, {
            [sideBarLayoutContentNoSidebarStyles]: !hasItems,
        })} key="content">
                {content}
            </div>
        </div>);
    const topNavView = (<div className={sideBarLayoutContentMobileStyles}>
            <div className={isLargerThanIpad ? resumeTourButtonIpadContainerStyles : resumeTourButtonMobileContainerStyles}>{tourProgress}</div>
            {hasItems && (<div>
                    <PriorityNavigation maxNavigationItems={99} navigationItems={navItems.filter(isPageSideNavLink).map(convertNavLinkToMenuNodeItem)}/>
                </div>)}
            <div key="content">{content}</div>
        </div>);
    return (<div className={layoutContainerStyles} ref={containerRef}>
            <div className={contentContainerStyles}>
                {header && <PageHeaderPrimary {...header}/>}
                {callout}
                {hasEnoughSpaceForSideNav ? sideNavView : topNavView}
            </div>
        </div>);
}
function convertNavLinkToMenuNodeItem(navLink: PageSideNavLink): MenuNodeItem {
    return { url: navLink.path, text: navLink.label as string, exact: navLink.exact, onClick: navLink.onClick };
}
const layoutContainerStyles = css({
    flex: 1,
    display: "flex",
    justifyContent: "center",
});
const contentContainerStyles = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
});
const sideBarLayoutStyles = css({
    flex: 1,
    display: "flex",
});
const sideBarLayoutContentStyles = css({
    flex: "1",
    padding: `${space["16"]} ${space["32"]} ${space["16"]} 0`,
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    minWidth: 0,
});
const sideBarLayoutContentNoSidebarStyles = css({
    padding: `${space["16"]} ${space["32"]} ${space["16"]} ${space["32"]}`,
});
const sideBarLayoutContentMobileStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
});
const resumeTourButtonIpadContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["32"]}`,
});
const resumeTourButtonMobileContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["16"]}`,
});
