import { css, cx } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
type PolaroidCameraIconProps = {
    size?: "xSmall" | "small";
};
export function PolaroidCameraIcon({ size = "small" }: PolaroidCameraIconProps) {
    return (<svg className={size === "xSmall" ? xSmallIconStyles : iconStyles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 31C34.5 31 35 30.5625 35 30V28H5V30C5 30.5625 5.4375 31 6 31H34ZM6.25 25H33.6875L31.625 21.9375C31.1875 21.3125 31 20.5 31 19.75V10C31 9.5 30.5 9 30 9H10C9.4375 9 9 9.5 9 10V19.75C9 20.5 8.75 21.3125 8.3125 21.9375L6.25 25ZM6 34C3.75 34 2 32.25 2 30V27.25C2 26.4375 2.1875 25.6875 2.625 25L5.8125 20.3125C5.9375 20.125 6 19.9375 6 19.75V10C6 7.8125 7.75 6 10 6H30C32.1875 6 34 7.8125 34 10V19.75C34 19.9375 34 20.125 34.125 20.25L37.3125 25C37.75 25.6875 38 26.4375 38 27.25V30C38 32.25 36.1875 34 34 34H6ZM27.5 11C28.3125 11 29 11.6875 29 12.5C29 13.375 28.3125 14 27.5 14C26.625 14 26 13.375 26 12.5C26 11.6875 26.625 11 27.5 11ZM20 22C18.1875 22 16.5625 21.0625 15.625 19.5C14.75 18 14.75 16.0625 15.625 14.5C16.5625 13 18.1875 12 20 12C21.75 12 23.375 13 24.3125 14.5C25.1875 16.0625 25.1875 18 24.3125 19.5C23.375 21.0625 21.75 22 20 22ZM18 17C18 18.125 18.875 19 20 19C21.0625 19 22 18.125 22 17C22 15.9375 21.0625 15 20 15C18.875 15 18 15.9375 18 17Z"/>
        </svg>);
}
const xSmallIconStyles = cx(iconStyles, css({
    width: "20px",
    height: "20px",
}));
