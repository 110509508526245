import { css, cx } from "@emotion/css";
import { themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { useIsLargerThanIpadResolution, useIsLegacySmallScreen } from "../../hooks";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { Callout, type CalloutType } from "../Callout/Callout";
import type { SimpleMenuItem } from "../MenuItems/SimpleMenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions/PageActions";
import { PageHeaderPrimary } from "../PageHeaderPrimary";
import { LinearProgress } from "../Progress";
import { AdvancedFilters } from "./AdvancedFilters";
import { ErrorPanel, type ErrorInfo } from "./ErrorPanel";
import { Filters, FiltersSummary } from "./Filters";
import { useAdvancedFiltersVisibility } from "./useAdvancedFiltersVisibility";
interface Level1PageContentBaseProps {
    header: {
        title: string;
        chip?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    busy?: boolean;
    callout?: {
        title?: ReactNode;
        content: ReactNode;
        type: CalloutType;
        onClose?: () => void;
    };
    errors?: ErrorInfo[];
}
interface ContentProps {
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            hasUserSelectedValues: boolean;
        };
    };
    pagination?: {
        ui: ReactNode;
        placement: "top" | "bottom" | "topAndBottom";
    };
}
type Level1PageContentPropsWithoutTabs = Level1PageContentBaseProps & ContentProps & {
    children: React.ReactNode;
};
export type Level1PageContentProps = Level1PageContentPropsWithoutTabs;
export function Level1PageContent({ header, busy = false, callout, errors, ...contentProps }: Level1PageContentProps) {
    const content = contentProps.children;
    const filters = contentProps.filters;
    const pagination = contentProps.pagination;
    const isSmallScreen = useIsLegacySmallScreen();
    const isLargerThanIPad = useIsLargerThanIpadResolution();
    const [isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] = useAdvancedFiltersVisibility(filters?.advancedFilters);
    const paginationAtBottom = pagination && pagination.placement !== "top";
    const paginationAtTop = pagination && pagination.placement !== "bottom";
    const hasAdvancedFilters = filters?.advancedFilters !== undefined;
    const hasFilters = (filters?.inputs && filters.inputs.length > 0) || hasAdvancedFilters;
    const hasFilterAndPaginationSection = hasFilters || filters?.filtersSummary !== undefined || paginationAtTop;
    return (<div className={pageContentStyles.container}>
            <PageHeaderPrimary breadcrumbs={header.breadcrumbs} title={header.title} titleChips={header.chip ? [header.chip] : undefined} primaryAction={header.primaryAction} actions={header.pageActions} overflowActions={header.overflowActions}/>
            <div className={isLargerThanIPad ? pageContentStyles.largePaddingContainer : pageContentStyles.smallPaddingContainer}>
                <div className={pageContentStyles.progressBarContainer}>
                    <LinearProgress variant={"indeterminate"} show={busy}/>
                </div>
                {errors?.map((error, index) => (<ErrorPanel key={index} error={error}/>))}
                {callout && (<Callout type={callout.type} title={callout.title} hideTitle={!callout.title} onClose={callout.onClose} canClose={!!callout.onClose}>
                        {callout.content}
                    </Callout>)}
                {hasFilterAndPaginationSection && (<div className={pageContentStyles.filtersAndPaginationSection}>
                        <div className={pageContentStyles.filtersAndPaginationRow}>
                            {hasFilters && <Filters inputs={filters.inputs} showAdvancedFiltersToggle={hasAdvancedFilters} isAdvancedFiltersVisible={isAdvancedFiltersVisible} onAdvancedFiltersVisibilityChanged={setIsAdvancedFiltersVisible}/>}
                            {paginationAtTop && pagination.ui}
                        </div>
                        {filters?.filtersSummary && <FiltersSummary summary={filters.filtersSummary}/>}
                    </div>)}
                <div className={cx(pageContentStyles.sectionsWithSidebars, {
            [pageContentStyles.sectionsWithSidebarsVertical]: isSmallScreen,
        })}>
                    {filters?.advancedFilters && isAdvancedFiltersVisible && (<AdvancedFilters content={filters.advancedFilters.content} onResetFilter={filters.advancedFilters.onResetFilter} isResetEnabled={filters.advancedFilters.hasUserSelectedValues} parentOrientation={isSmallScreen ? "column" : "row"}/>)}
                    <div className={pageContentStyles.contentBox}>{content}</div>
                </div>
                {paginationAtBottom && <div className={pageContentStyles.footerPaginationSection}>{pagination.ui}</div>}
            </div>
        </div>);
}
const pageContentStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        font: text.regular.default.medium,
    }),
    smallPaddingContainer: css({
        padding: `0 ${space[16]} ${space[16]}`,
    }),
    largePaddingContainer: css({
        padding: `0 ${space[32]} ${space[32]}`,
    }),
    progressBarContainer: css({
        paddingBottom: space[8],
    }),
    filtersAndPaginationSection: css({
        gap: space[8],
    }),
    filtersAndPaginationRow: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    sectionsWithSidebars: css({
        display: "flex",
        flex: 1,
        color: themeTokens.color.text.primary,
        marginTop: space[24],
    }),
    sectionsWithSidebarsVertical: css({
        flexDirection: "column",
    }),
    contentBox: css({
        flex: 1,
        minWidth: 0,
    }),
    footerPaginationSection: css({
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: space[16],
    }),
};
