import { css, cx } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import { resetStyles } from "../../utils";
import { ActionButton, ActionButtonType } from "../Button";
export interface AdvancedFilterProps {
    content: ReactNode;
    onResetFilter: () => void;
    isResetEnabled: boolean;
    parentOrientation: "row" | "column";
}
export function AdvancedFilters({ content, onResetFilter, isResetEnabled, parentOrientation }: AdvancedFilterProps) {
    return (<div className={cx(advancedFiltersStyles.container, {
            [advancedFiltersStyles.inRow]: parentOrientation === "row",
            [advancedFiltersStyles.inColumn]: parentOrientation === "column",
        })}>
            <div className={advancedFiltersStyles.header}>
                <h4 className={advancedFiltersStyles.heading}>Advanced filter</h4>
                <ActionButton disabled={!isResetEnabled} label="Reset" type={ActionButtonType.Secondary} onClick={() => onResetFilter()}/>
            </div>
            {content}
        </div>);
}
const advancedFiltersStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        padding: `${space[8]} ${space[16]}`,
        gap: space[8],
    }),
    inRow: css({
        width: "17.6rem",
        borderRight: `1px solid ${themeTokens.color.border.primary}`,
    }),
    inColumn: css({
        borderTop: `1px solid ${themeTokens.color.border.primary}`,
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: space[16],
    }),
    heading: css({
        ...resetStyles.heading,
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
    }),
};
