import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { ActionButton, ActionButtonType } from "../Button";
import { IconButton } from "../IconButton";
import { SimpleMenu, useMenuState } from "../Menu";
import type { SimpleMenuItem } from "../MenuItems";
type TotalResultsProps = {
    /**
     * The total results that have been paginated.
     */
    totalResults: "unknown";
    /**
     * Whether there are more pages to show.
     */
    hasMoreResults: boolean;
} | {
    /**
     * The total results that have been paginated.
     */
    totalResults: number;
};
export type PaginationProps = {
    /**
     * The label for the pagination controls.
     */
    label: string;
    /**
     * The current page being displayed.
     */
    currentPage: number;
    /**
     * The range of options to present for the number of items per page.
     */
    itemsPerPageOptions: readonly number[];
    /**
     * The selected number of items per page.
     */
    selectedItemsPerPage: number;
    /**
     * The callback to run when the page is changed.
     */
    onPageChange: (page: number) => void;
    /**
     * The callback to run when the page size is changed.
     */
    onPageSizeChange: (pageSize: number) => void;
} & TotalResultsProps;
export function Pagination({ label, currentPage, itemsPerPageOptions, selectedItemsPerPage, onPageChange, onPageSizeChange, ...props }: PaginationProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const hasTotalResults = props.totalResults !== "unknown";
    const pageStart = Math.min((currentPage - 1) * selectedItemsPerPage + 1, hasTotalResults ? props.totalResults : Infinity);
    const pageEnd = Math.min(currentPage * selectedItemsPerPage, hasTotalResults ? props.totalResults : Infinity);
    const totalResultsText = hasTotalResults ? `${pageStart} - ${pageEnd} of ${props.totalResults} ${label}` : `${pageStart} - ${pageEnd} ${label}`;
    const previousIsDisabled = currentPage === 1;
    const nextIsDisabled = hasTotalResults ? currentPage * selectedItemsPerPage >= props.totalResults : !props.hasMoreResults;
    const hasPageSizeOptions = itemsPerPageOptions.length > 0;
    const menuItems: SimpleMenuItem[] = [];
    menuItems.push({
        type: "information",
        content: <span className={resultsPerPageMenuItemStyles}>{label} per page</span>,
    });
    itemsPerPageOptions.map((itemsPerPage) => {
        menuItems.push({
            type: "button",
            label: `${itemsPerPage}`,
            onClick: () => onPageSizeChange(itemsPerPage),
            isSelected: itemsPerPage === selectedItemsPerPage,
        });
    });
    return (<div className={paginationWrapper}>
            <div className="tracker-pagination">
                <ActionButton label={totalResultsText} onClick={hasPageSizeOptions ? (e) => openMenu(e) : undefined} type={ActionButtonType.Ternary} menuButtonAttributes={buttonAriaAttributes}/>
                {hasPageSizeOptions && <SimpleMenu compact={true} menuState={menuState} items={menuItems} accessibleName={"Change page size"}/>}
            </div>
            <div className={iconButtonWrapper}>
                <IconButton onClick={() => onPageChange(currentPage - 1)} icon="ChevronLeft" accessibleName="Previous" disabled={previousIsDisabled} disableRipple={true}/>
            </div>
            <div className={iconButtonWrapper}>
                <IconButton onClick={() => onPageChange(currentPage + 1)} icon="ChevronRight" accessibleName="Next" disabled={nextIsDisabled} disableRipple={true}/>
            </div>
        </div>);
}
const resultsPerPageMenuItemStyles = css({
    font: text.regular.bold.medium,
});
const paginationWrapper = css({
    display: "flex",
    alignItems: "center",
    gap: space["2"],
});
const iconButtonWrapper = css({
    padding: space["6"],
});
