import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { PropsWithChildren } from "react";
export const popoverWidthOptions = ["narrow", "wide", "extra-wide"] as const;
export type PopoverWidth = (typeof popoverWidthOptions)[number];
export interface PopoverContentContainerProps {
    width?: PopoverWidth;
}
export function PopoverContentContainer({ width, children }: PropsWithChildren<PopoverContentContainerProps>) {
    return <div className={cx(popoverContentStyles, { [wideWidth]: width === "wide" }, { [extraWideWidth]: width === "extra-wide" })}>{children}</div>;
}
const popoverContentStyles = css({
    padding: space[24],
    maxWidth: "20.5rem",
});
const wideWidth = css({
    maxWidth: "25rem",
});
const extraWideWidth = css({
    maxWidth: "780px",
});
