import { css } from "@emotion/css";
import { borderRadius, space, text } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import { Popover } from "./Popover";
import type { PopoverProps } from "./Popover";
import { PopoverContentContainer } from "./PopoverContentContainer";
import type { PopoverWidth } from "./PopoverContentContainer";
export interface PopoverBasicProps extends PopoverProps {
    image?: string;
    video?: string;
    altText?: string;
    title?: string;
    description: ReactNode;
    link1?: ReactNode;
    link2?: ReactNode;
    width?: PopoverWidth;
}
export function PopoverBasic({ width = "narrow", ...props }: PopoverBasicProps): JSX.Element {
    return (<Popover placement={props.placement} onClose={props.onClose} open={props.open} anchorEl={props.anchorEl}>
            <PopoverContentContainer width={width}>
                <div className={styles.container}>
                    {props.image && <img className={styles.image} src={props.image}/>}
                    {props.video && (<p>
                            <video src={props.video} playsInline={true} autoPlay={true} loop={true} muted={true} className={styles.video}/>
                        </p>)}
                    {props.title && (<div className={styles.title}>
                            <div>{props.title}</div>
                        </div>)}
                    <div className={styles.content}>{props.description}</div>
                    <div className={styles.links}>
                        {props.link1}
                        {props.link2}
                    </div>
                </div>
            </PopoverContentContainer>
        </Popover>);
}
const styles = {
    container: css({
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
        gap: space[8],
    }),
    title: css({
        font: text.regular.bold.medium,
    }),
    content: css({
        font: text.regular.default.medium,
        display: "flex",
        flexDirection: "column",
        gap: space[8],
    }),
    image: css({
        width: "100%",
    }),
    video: css({
        width: "100%",
        borderRadius: borderRadius.small,
    }),
    links: css({
        display: "flex",
        alignItems: "center",
        gap: space[16],
        font: text.regular.bold.medium,
    }),
};
